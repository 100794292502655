import * as React from 'react'
import Layout from '../components/layout'
import { Container } from '../components/container'
import { Question } from '../components/question'
import { Response } from '../components/response'
import { SectionTitle } from '../components/section-title'
import { useTranslation } from 'react-i18next'
import { Wizard, Step, useControls, Controls } from "react-decision-tree-flow";
import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const QuizPage = () => {
  const { t } = useTranslation()

  const translationsEn = require('../locale/en/translation.json')
  const translationsNl = require('../locale/nl/translation.json')
  const translationsDe = require('../locale/de/translation.json')
  const translationsFr = require('../locale/fr/translation.json')

  const quizPage = 'quiz';

  const hrefLangLinks = {
    en: translationsEn[quizPage],
    fr: translationsFr[quizPage],
    nl: translationsNl[quizPage],
    de: translationsDe[quizPage],
  }

  const tree = {
    start: ['start_vagina', 'start_penis', 'other'],
    start_vagina: ['vagina6months_yes', 'vagina6months_no'],
    vagina6months_yes: ['vaginarisk_yes', 'vaginarisk_no'],
    vaginarisk_yes: ['vaginalanaloral', 'vaginaloral', 'vaginal', 'anal', 'genitalrubbing'],
    vaginarisk_no: ['female_generalprevention', 'female_comprehensiveprevention', 'female_unsure'],
    vagina6months_no: ['female_generalprevention', 'vaginarisk_symptoms', 'femalequestion_unsure'],
    vaginarisk_symptoms: ['vaginalanaloral', 'vaginaloral', 'vaginal', 'anal', 'genitalrubbing'],
    femalequestion_unsure: ['femalequestion_yes', 'femalequestion_no'],
    femalequestion_yes: [],
    femalequestion_no: [],
    vaginalanaloral: [],
    vaginaloral: [],
    vaginal: [],
    genitalrubbing: [],
    female_generalprevention: [],
    female_comprehensiveprevention: [],
    female_unsure: [],
    start_penis: ['penis6months_yes', 'penis6months_no'],
    penis6months_yes: ['penisrisk_yes', 'penisrisk_no'],
    penisrisk_yes: ['havewithwomen', 'havewithmen', 'havewithmenandwomen'],
    havewithwomen: [],
    havewithmen: [],
    havewithmenandwomen: [],
    penisrisk_no: ['male_generalprevention', 'male_comprehensiveprevention', 'male_unsure'],
    penis6months_no: ['male_generalprevention', 'penis_symptoms', 'malequestion_unsure'],
    penis_symptoms: [],
    malequestion_unsure: ['malequestion_yes', 'malequestion_no'],
    malequestion_yes: [],
    malequestion_no: [],
    male_generalprevention: [],
    male_comprehensiveprevention: [],
    male_unsure: [],
    anal: [],
    other: []
  } as const;

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const WizardInternals = () => {
    const { destinations } = useControls<typeof tree>();
    const [selected, setSelected] = useState(Object.entries(destinations)[0]);
    return (
      <>
        <Step name="start">
          <Question first={true} title={t('quiz-wichstatements')} />
        </Step>
        <Step name="start_vagina">
          <Question title={t('quiz-multiple')} />
        </Step>
        <Step name="vagina6months_yes">
          <Question title={t('quiz-riskofcontracting')} />
        </Step>
        <Step name="vaginarisk_yes">
          <Question title={t('quiz-lastintercourse')} />
        </Step>
        <Step name="vaginarisk_symptoms">
          <Question title={t('quiz-lastintercourse')} />
        </Step>
        <Step name="vaginalanaloral">
          <Response testTypes={[
            { name: 'STD ANAL', link: '/our-tests/std-tests/std-anal-test' },
            { name: 'STD STANDARD FEMALE', link: 'our-tests/std-tests/std-test-female', recommended: true },
            { name: 'STD PLUS FEMALE', link: '/our-tests/std-tests-plus/std-plus-test-or-female' },
            { name: 'STD COMPLETE FEMALE', link: '/our-tests/std-tests-plus/complete-std-or-female', mostComprehensive: true }]} />
        </Step>
        <Step name="vaginaloral">
          <Response testTypes={[
            { name: 'STD STANDARD FEMALE', link: 'our-tests/std-tests/std-test-female', recommended: true },
            { name: 'STD PLUS FEMALE', link: '/our-tests/std-tests-plus/std-plus-test-or-female' },
            { name: 'STD COMPLETE FEMALE', link: '/our-tests/std-tests-plus/complete-std-or-female' }]} />
        </Step>
        <Step name="vaginal">
          <Response testTypes={[
            { name: 'STD STANDARD FEMALE', link: 'our-tests/std-tests/std-test-female' },
            { name: 'STD PLUS FEMALE', link: '/our-tests/std-tests-plus/std-plus-test-or-female' },
            { name: 'STD COMPLETE FEMALE', link: '/our-tests/std-tests-plus/complete-std-or-female' }]} />
        </Step>
        <Step name="anal">
          <Response testTypes={[
            { name: 'STD ANAL', link: '/our-tests/std-tests/std-anal-test' }]} />
        </Step>
        <Step name="genitalrubbing">
          <Response testTypes={[
            { name: 'STD STANDARD FEMALE', link: 'our-tests/std-tests/std-test-female' },
            { name: 'STD PLUS FEMALE', link: '/our-tests/std-tests-plus/std-plus-test-or-female' },
            { name: 'STD COMPLETE FEMALE', link: '/our-tests/std-tests-plus/complete-std-or-female' }]} />
        </Step>
        <Step name="vaginarisk_no">
          <Question title={t('quiz-lastidentify')} />
        </Step>
        <Step name="female_generalprevention">
          <Response testTypes={[
            { name: 'STD STANDARD FEMALE', link: 'our-tests/std-tests/std-test-female' }]} />
        </Step>
        <Step name="female_comprehensiveprevention">
          <Response testTypes={[
            { name: 'STD COMPLETE FEMALE', link: '/our-tests/std-tests-plus/complete-std-or-female' }]} />
        </Step>
        <Step name="female_unsure">
          <Response testTypes={[
            { name: 'STD STANDARD FEMALE', link: 'our-tests/std-tests/std-test-female' }]} />
        </Step>
        <Step name="vagina6months_no">
          <Question title={t('quiz-identify')} />
        </Step>
        <Step name="femalequestion_unsure">
          <Question title={t('quiz-lastactive')} />
        </Step>
        <Step name="femalequestion_yes">
          <Response testTypes={[
            { name: 'STD STANDARD FEMALE', link: 'our-tests/std-tests/std-test-female' }]} />
        </Step>
        <Step name="femalequestion_no">
          <Response text={t('quiz-feelfree')} />
        </Step>
        <Step name="start_penis">
          <Question title={t('quiz-multiple')} />
        </Step>
        <Step name="penis6months_yes">
          <Question title={t('quiz-riskofcontracting')} />
        </Step>
        <Step name="penisrisk_yes">
          <Question title={t('quiz-lastintercourse')} />
        </Step>
        <Step name="havewithwomen">
          <Response testTypes={[
            { name: 'STD STANDARD MALE', link: '/our-tests/std-tests/std-test-male', recommended: true },
            { name: 'STD PLUS MALE', link: '/our-tests/std-tests-plus/std-plus-test-male' },
            { name: 'STD COMPLETE MALE', link: '/our-tests/std-tests-plus/complete-std-or-male', mostComprehensive: true }]} />
        </Step>
        <Step name="havewithmen">
          <Response testTypes={[
            { name: 'STD ANAL', link: '/our-tests/std-tests/std-anal-test' },
            { name: 'STD COMPLETE MALE', link: '/our-tests/std-tests-plus/complete-std-or-male', recommended: true }]} />
        </Step>
        <Step name="havewithmenandwomen">
          <Response testTypes={[
            { name: 'STD ANAL', link: '/our-tests/std-tests/std-anal-test' },
            { name: 'STD COMPLETE MALE', link: '/our-tests/std-tests-plus/complete-std-or-male', recommended: true }]} />
        </Step>
        <Step name="penisrisk_no">
          <Question title={t('quiz-lastidentify')} />
        </Step>
        <Step name="male_generalprevention">
          <Response testTypes={[
            { name: 'STD STANDARD MALE', link: '/our-tests/std-tests/std-test-male' }]} />
        </Step>
        <Step name="penis_symptoms">
          <Response testTypes={[
            { name: 'STD STANDARD MALE', link: '/our-tests/std-tests/std-test-male' },
            { name: 'STD PLUS MALE', link: '/our-tests/std-tests-plus/std-plus-test-male' },
            { name: 'STD COMPLETE MALE', link: '/our-tests/std-tests-plus/complete-std-or-male' }]} />
        </Step>
        <Step name="male_comprehensiveprevention">
          <Response testTypes={[
            { name: 'STD COMPLETE MALE', link: '/our-tests/std-tests-plus/complete-std-or-male' }]} />
        </Step>
        <Step name="male_unsure">
          <Response testTypes={[
            { name: 'STD STANDARD MALE', link: '/our-tests/std-tests/std-test-male' }]} />
        </Step>
        <Step name="penis6months_no">
          <Question title={t('quiz-identify')} />
        </Step>
        <Step name="malequestion_unsure">
          <Question title={t('quiz-lastactive')} />
        </Step>
        <Step name="malequestion_yes">
          <Response testTypes={[
            { name: 'STD STANDARD MALE', link: '/our-tests/std-tests/std-test-male' }]} />
        </Step>
        <Step name="malequestion_no">
          <Response text={t('quiz-feelfree')} />
        </Step>
        <Step name="other">
          <Response text={t('quiz-all')} testTypes={[
            { name: 'STD STANDARD MALE', link: '/our-tests/std-tests/std-test-male', },
            { name: 'STD PLUS MALE', link: '/our-tests/std-tests-plus/std-plus-test-male' },
            { name: 'STD COMPLETE MALE', link: '/our-tests/std-tests-plus/complete-std-or-male' }]} />
        </Step>
        {
          <RadioGroup value={selected} onChange={setSelected}>
            <RadioGroup.Label className="sr-only"> Server size </RadioGroup.Label>
            <div className="space-y-4">
              {
                Object.entries(destinations).map(([stepName, goToStep]) => {
                  return (
                    <RadioGroup.Option
                      key={stepName}
                      value={stepName}
                      onClick={goToStep}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? 'border-transparent' : 'border-abel-green',
                          active ? 'border-abel-green-darker ring-2 ring-abel-green-darker' : '',
                          'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span className="flex items-center">
                            <span className="flex flex-col text-sm">
                              <RadioGroup.Label as="span" className="font-medium text-abel-gray">
                                {t("quiz-" + stepName.substring(stepName.indexOf('_') + 1, stepName.length))}
                              </RadioGroup.Label>
                            </span>
                          </span>
                          <span
                            className={classNames(
                              active ? 'border' : 'border-2',
                              checked ? 'border-abel-green-darker' : 'border-transparent',
                              'pointer-events-none absolute -inset-px rounded-lg'
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  );
                })
              }
            </div>
          </RadioGroup>
        }
      </>
    );
  };

  return (
    <Layout hrefLangLinks={hrefLangLinks} >
      <Container color="bg-beige">
        <SectionTitle title={t('quiz-title')} centered={true} main={true} />
        <Wizard tree={tree} first="start">
          <WizardInternals />
        </Wizard>
      </Container>
    </Layout>
  )
}

export default QuizPage