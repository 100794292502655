import * as React from 'react'
import { Controls } from 'react-decision-tree-flow'

interface QuestionProps {
  title: string,
  first?: boolean
}

export function Question({
  title,
  first
}: QuestionProps) {
  return (
    <>
      <h2 className={`text-2xl sm:text-3xl md:text-4xl font-light font-serif text-gray-900 pb-4`}>
        {title}
      </h2>
      {first ?? (
        <Controls>
          {({ back }) => <button className='border border-abel-green ring-abel-green bg-white text-abel-gray rounded-md w-12 text-center py-3 text-lg mt-4 mb-4 mr-4' onClick={back}>&#8592;</button>}
        </Controls>
      )
      }
    </>
  )
}
