import * as React from 'react'
import { Controls } from 'react-decision-tree-flow'
import { useTranslation } from 'react-i18next'

interface TestType {
  name: string,
  link?: string,
  recommended?: boolean,
  mostComprehensive?: boolean
}

interface ResponseProps {
  testTypes?: TestType[],
  text?: string
}

export function Response({
  testTypes,
  text
}: ResponseProps) {
  const { t } = useTranslation()
  return (
    <>
      <h2 className={`text-2xl sm:text-3xl md:text-4xl font-light font-serif text-gray-900 pb-4`}>
        {t('quiz-results')}:
      </h2>
      {text ? (
        <div className="mb-4">
          <span className="text-1xl sm:text-2xl md:text-3xl font-light font-serif text-gray-900">
            {text}
          </span>
        </div>
      ) : (<></>)
      }
      {testTypes && (
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {
            testTypes.map((test) => {
              return (
                <li key={test.name} className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
                  <a href={test.link}>
                    <div className="flex flex-1 flex-col p-8">
                      <span className={`text-1xl sm:text-2xl md:text-3xl font-light font-serif text-gray-900 pb-4`}>
                        {test.name}
                      </span>
                      <dl className="mt-1 flex flex-grow flex-col justify-between">
                        {test.recommended ? (
                          <>
                            <dt className="sr-only">{t('quiz-recommended')}</dt>
                            <dd className="mt-3">
                              <span className="rounded-full bg-abel-green px-4 py-2 text-sm font-medium text-white">
                                {t('quiz-recommended')}
                              </span>
                            </dd>
                          </>
                        ) : (<></>)
                        }
                        {test.mostComprehensive ? (
                          <>
                            <dt className="sr-only">{t('quiz-mostcomprehensive')}</dt>
                            <dd className="mt-3">
                              <span className="rounded-full bg-abel-green px-4 py-2 text-sm font-medium text-white">
                                {t('quiz-mostcomprehensive')}
                              </span>
                            </dd>
                          </>
                        ) : (<></>)
                        }
                      </dl>
                    </div>
                  </a>
                </li>
              );
            }
            )
          }
        </ul>
      )
      }
      <Controls>
        {({ back }) => <button className='mb-12 border-white bg-white text-abel-green rounded-md w-12 text-center py-3 text-lg mt-4 mr-4 hover:bg-abel-gray' onClick={back}>&#8592;</button>}
      </Controls>
    </>
  )
}
